import "./sentryInstrument";
import * as Sentry from "@sentry/browser";


async function setSECodeCookie(url, retriesLeft) {
  try {
	const response = await fetch(url);
	if (!response.ok) {
	  throw new Error(`Unexpected ${response.statusText} response ${response.status} from ${url}`);
	}
  } catch (error) {
	if (retriesLeft > 0) {
	  await new Promise(r => setTimeout(r, 2000));	// NOTE: sleep 2 seconds
	  await setSECodeCookie(url, retriesLeft - 1);
	} else {
	  Sentry.captureException(error);
	}
  }
}

window.addEventListener("DOMContentLoaded", () => {
  if (window.setSECodeURL) {
	setSECodeCookie(window.setSECodeURL, 4);
  }
});
